import { createSlice } from "@reduxjs/toolkit"; 

export const initialStateValue = "";

export const userSlice = createSlice({
  name: "userSettings",
  initialState: { email: initialStateValue },
  reducers: {
    setUserMail:(state, action) => {
      state.email = action.payload;
    }
  }
})

export const { setUserMail } = userSlice.actions
export default userSlice.reducer