import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloLink,
  concat,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

import './Services/locales/i18n';
import App from './App';

import { store } from './Services/store/store';
import { Logout } from './Services/helpers/helpers';


const gtagId = process.env.REACT_APP_GTAG_ID;
const injectGtagScript = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  script.async = true;
  document.head.appendChild(script);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gtagId}');
  `;
  document.head.appendChild(script2);
};

injectGtagScript();

const httpLink = new HttpLink({ uri: process.env.REACT_APP_ENDPOINT });

let token = Cookies.get('access_token');
let lang = localStorage.getItem('i18nextLng');
lang = !lang.includes(['en', 'th', 'zh']) ? lang : 'en';
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: token ? token : '',
      lang: lang,
    },
  });
  return forward(operation);
});

const logoutLink = onError(({ networkError }) => {
  if (networkError.statusCode === 401) Logout();
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink, logoutLink),
  cache: new InMemoryCache(),
});

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new BrowserTracing(),  new Sentry.Replay()],
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter forceRefresh>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
