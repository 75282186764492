import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const parseJwt = () => {
  let cookie = Cookies.get('access_token');
  try {
    if (cookie) {
      let decoded = jwt_decode(cookie);
      return decoded;
    }
  } catch (error) {
    console.log(error);
  }
};
