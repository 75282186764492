import { createSlice } from "@reduxjs/toolkit"; 

const initialState = {
  step1: true,
  step2: false,
  step3: false,
}

export const stepSlice = createSlice({
  name: "loadingLoginStore",
  initialState: initialState,
  reducers: {
    activeStep1: (state) =>{
      state.step1 = true;
      state.step2 = false;
      state.step3 = false;
    },
    activeStep2: (state) =>{
      state.step1 = true;
      state.step2 = true;
      state.step3 = false;
    },
    activeStep3: (state) =>{
      state.step1 = true;
      state.step2 = true;
      state.step3 = true;
    },
  }
})

export const { activeStep1, activeStep2, activeStep3 } = stepSlice.actions
export default stepSlice.reducer