import { createSlice, current } from '@reduxjs/toolkit';

const initialValue = {
  booking: null,
};

const bookingStoreSlice = createSlice({
  name: 'bookingStore',
  initialState: initialValue,
  reducers: {
    setBookingStore: (state, action) => {
      const result = action.payload;
      state.booking = result;
      console.log('localStore',current(state));
    },
  },
});

export const { setBookingStore } = bookingStoreSlice.actions;
export default bookingStoreSlice.reducer;
