import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  Cookies:
    localStorage.getItem('cookie') == 'true' && Cookies.get('lang_name')
      ? true
      : false,
  CookieSetting: true,
};

export const CookieSlice = createSlice({
  name: 'cookieStore',
  initialState: initialState,
  reducers: {
    openCookie: (state) => {
      state.Cookies = false;
    },
    closeCookie: (state) => {
      state.Cookies = true;
    },
    openCookieSetting: (state) => {
      state.Cookies = true;
    },
    closeCookieSetting: (state) => {
      state.CookieSetting = false;
    },
  },
});

export const {
  openCookie,
  closeCookie,
  openCookieSetting,
  closeCookieSetting,
} = CookieSlice.actions;
export default CookieSlice.reducer;
