// import * as Sentry from '@sentry/react';
import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoutes from './Routes/PrivateRoute';

const Home = lazy(() => import('./Pages/home/'));
const Error = lazy(() => import('./Pages/Error'));
const Layout = lazy(() => import('./Layouts/index'));
const Profile = lazy(() => import('./Pages/profile'));
const Register = lazy(() => import('./Pages/register'));
const Detail = lazy(() => import('./Pages/detail/detail.js'));

const Booking = lazy(() => import('./Pages/booking'));
const BookingPayment = lazy(() => import('./Pages/bookingPayment'));
const PremiumService = lazy(() => import('./Pages/profile/premiums'));

const PaymentResult = lazy(() => import('./Pages/paymentResult'));
const BookingCancel = lazy(() => import('./Pages/cancel/bookingCancel'));
const ReasonCancel = lazy(() => import('./Pages/cancel/reasonCancel'));

const Voucher = lazy(() => import('./Pages/profile/voucher'));
const Account = lazy(() => import('./Pages/profile/account'));

const BookingInfo = lazy(() => import('./Pages/bookingInfo'));
const BookingEdit = lazy(() => import('./Pages/bookingUpdate'));
const BookingCancelProcress = lazy(() =>
  import('./Pages/bookingCancelProcress')
);
const PrivacyPolicy = lazy(() => import('./Pages/privacyPolicy'));
const FrequentlyAskedQuestions = lazy(() =>
  import('./Pages/frequentlyAskedQuestions')
);
const TermsAndConditionsOfPremiumServiceUsers = lazy(() =>
  import('./Pages/termsCondition/premiumServiceUsers')
);
const Cookie = lazy(() => import('./Pages/cookie'));
const CookieSetting = lazy(() => import('./Pages/cookie/cookieSetting'));
const CookiePolicy = lazy(() => import('./Pages/cookie/cookiePolicy'));
const RatingFeedbackBooking = lazy(() =>
  import('./Pages/ratingFeedbackBooking')
);

const BookingStatus = lazy(() => import('./Pages/bookingStatus'));

function App() {
  return (
    <>
      <Layout>
        <Suspense fallback={<></>}>
          <Routes>
            {/* // ! Pubilc Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/frequentlyAskedQuestions"
              element={<FrequentlyAskedQuestions />}
            />
            <Route
              path="/terms-condition"
              element={<TermsAndConditionsOfPremiumServiceUsers />}
            />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="/cookie-setting" element={<CookieSetting />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/rating-booking" element={<RatingFeedbackBooking />} />

            {/* // ? check register */}
            <Route path="/register" element={<Register />} />
            <Route path="/booking-detail/:slug" element={<Detail />} />
            {/* // ! Private Routes   */}
            <Route element={<PrivateRoutes />}>
              {/* // todo booking */}
              <Route path="/payment-result" element={<PaymentResult />} />

              <Route path="/booking/:slug" element={<Booking />} />
              <Route path="/booking-edit" element={<BookingEdit />} />
              <Route
                path="/booking-payment/:orderId"
                element={<BookingPayment />}
              />
              <Route path="/booking-cancel" element={<BookingCancel />} />
              <Route path="/booking-reason" element={<ReasonCancel />} />
              <Route
                path="/booking-info/:id"
                element={<BookingInfo />}
                forceRefresh={true}
              />
              <Route
                path="/booking-info/:id/cancel"
                element={<BookingCancelProcress />}
              />
              <Route
                path="/booking-status/:status"
                element={<BookingStatus />}
              />

              <Route path="/profile" element={<Profile />}>
                <Route index element={<PremiumService />} />
                <Route path="premium" element={<PremiumService />} />
                <Route path="voucher" element={<Voucher />} />
                <Route path="account" element={<Account />} />
              </Route>
            </Route>

            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </Layout>
    </>
  );
}

export default App;
// export default Sentry.withProfiler(App);
