import { createSlice, current } from '@reduxjs/toolkit';

const initialValue = {
  nationality: null,
};

const nationalityStoreSlice = createSlice({
  name: 'nationalityStore',
  initialState: initialValue,
  reducers: {
    setNationalityStore: (state, action) => {
      const result = action.payload;
      state.nationality = result;
    },
  },
});

export const { setNationalityStore } = nationalityStoreSlice.actions;
export default nationalityStoreSlice.reducer;
