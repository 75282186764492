import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoadingLogin: false,
  showLoadingRegister: false,
  activeForm: false,
  linkTo: false,
};

export const loadingLoginSlice = createSlice({
  name: 'loadingLoginStore',
  initialState: initialState,
  reducers: {
    openModel: (state) => {
      state.showLoadingLogin = true;
      state.activeForm = true;
      state.linkTo = false;
      document.body.style.overflow = 'hidden';
    },
    closeModel: (state) => {
      state.showLoadingLogin = false;
      state.activeForm = false;
      state.linkTo = false;
      document.body.style.overflow = '';
    },
    openRegisterModel: (state) => {
      state.showLoadingRegister = true;
      state.activeForm = true;
      state.linkTo = false;
    },
    closeRegisterModel: (state) => {
      state.showLoadingRegister = false;
      state.activeForm = false;
      state.linkTo = false;
    },
    openModelWtihLink: (state, linkTo) => {
      state.showLoadingLogin = true;
      state.activeForm = true;
      state.linkTo = linkTo.payload;
    },
  },
});

export const {
  openModel,
  closeModel,
  openRegisterModel,
  closeRegisterModel,
  openModelWtihLink,
} = loadingLoginSlice.actions;
export default loadingLoginSlice.reducer;
