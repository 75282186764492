import { createSlice } from "@reduxjs/toolkit"; 

export const initialStateValue = {
  buggy:0,
  personal:0,
};

export const countSlice = createSlice({
  name: "count",
  initialState: initialStateValue ,
  reducers: {
    incrementBuggy(state) {
      state.buggy += 1;
    },
    decrementBuggy(state){
       state.buggy -= 1
    },
    incrementPersonal(state) {
      state.buggy += 1;
    },
    decrementPersonal(state){
       state.buggy -= 1
    },
  }
})

export const { incrementBuggy, decrementPersonal } = countSlice.actions
export default countSlice.reducer