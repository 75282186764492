import { configureStore } from '@reduxjs/toolkit';

import stepSlice from './stepSlice';
import setUserMail from './userSlice';
import countSlice from './countSlice';
import cookieSlice from './cookieSlice';
import languageSlice from './languageSlice';
import loadingLoginSlice from './loaderSlice';
import bookingStoreSlice from './bookingSlice';
import nationalitySlice from './nationalitySlice';

export const store = configureStore({
  reducer: {
    count: countSlice,
    cookies: cookieSlice,
    stepBooking: stepSlice,
    language: languageSlice,
    setUserMail: setUserMail,
    bookingStore: bookingStoreSlice,
    popupComponent: loadingLoginSlice,
    nationalityStore: nationalitySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
