import { createSlice } from "@reduxjs/toolkit"; 

export const initialStateValue = '';
export const languageSlice = createSlice({
  name: "language",
  initialState: initialStateValue,
  reducers: {
    language:(state, action) => {
      state.push(action.payload)
      return state
    }
  }
})

export const { language } = languageSlice.actions
export default languageSlice.reducer