import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

import en from './en/translation.json';
import th from './th/translation.json';
import zh from './zh/translation.json';

const lang = localStorage.getItem('i18nextLng');

const createI18n = (language) => {
  const i18n = i18next.createInstance().use(initReactI18next);
  let checkLang = Cookies.get('app_language') || language;

  if (checkLang !== 'en' && checkLang !== 'th' && checkLang !== 'zh')
    checkLang = 'en';

  i18n.use(LanguageDetector).init({
    debug: true,
    lng: checkLang,
    fallbackLng: checkLang,
    interpolation: { escapeValue: false },
    resources: { en: en, th: th, zh: zh },
  });
  return i18n;
};

export const i18n = createI18n(lang);
